import { createStore } from 'vuex'

export default createStore({
  state: {
    userInfo: null,       //用户信息
    companyInfo: null,    //公司信息
    shopInfo: null,       //门店信息
    sysParam: [],         //系统参数
    powerList: [],        //权限集合
    menuList: [],         //菜单集合
    tabList: [],          //tab集合
    currRouter: {},       //当前路由页
    activePageUUID: null, //页面uuid
    rolePower:null,       //分配角色权限ID
    comParam:null,        //公共传递的参数
  },
  getters:{
    //获取tab页
    getKeepAliveList(state){
      let list = [];
      for (let i = 0; i < state.tabList.length; i++) {
        list.push(state.tabList[i].name)
      }
      return list
    },
    //获取指定系统参数
    getSysParamList(state, getters){
      return logicName => {
        let list = [];
        for (let i = 0; i < state.sysParam.length; i++) {
          if(state.sysParam[i].logicName == logicName){
            list = state.sysParam[i].sysParamValueList;
          }
        }
        return list
      }
    },
  },
  mutations: {
    setComParam (state, comParam) {
      state.comParam = comParam
    },
    setActivePageUUID (state, activePageUUID) {
      state.activePageUUID = activePageUUID
    },
    setRolePower (state, rolePower) {
      state.rolePower = rolePower
    },
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
    },
    setCompanyInfo (state, companyInfo) {
      state.companyInfo = companyInfo
    },
    setShopInfo (state, shopInfo) {
      state.shopInfo = shopInfo
    },
    setSysParam (state, sysParam) {
      state.sysParam = sysParam
    },
    setMenuList (state, menuList) {
      state.menuList = menuList
    },
    setPowerList (state, powerList) {
      state.powerList = powerList
    },
    setTabList (state, tabList) {
      state.tabList = tabList
    },
    setCurrRouter (state, currRouter) {
      state.currRouter = currRouter
    },
  },
  actions: {
    // setToken ({ commit },token) {
    //   commit("setToken",token);
    // },
    // setUserInfo ({ commit },userInfo) {
    //   commit("setUserInfo",userInfo);
    // },
  },
  modules: {
  }
})
