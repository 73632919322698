<template>
    <div>
        <div class="row">
            <el-button v-if="currUser==''" @click="onAction('loginA')" type="primary" >玩家A登录</el-button>
            <el-button v-if="currUser==''" @click="onAction('loginB')" type="primary" >玩家B登录</el-button>

        </div>

        <div class="row" v-if="currUser!=''">
            <el-button @click="onAction('startMatching')" type="primary" >开始匹配</el-button>
            <el-button @click="onAction('playerReady')" type="primary" >载入完成</el-button>

            <div v-if="selectCard && selectCard.length > 0">
                <el-button v-for="(item,index) in selectCard" @click="onAction('selectCard', index)" type="primary" >{{index}}.{{item.cardName}}</el-button>
            </div>
        </div>

        <div class="row" v-if="currUser!=''">
            <el-button v-if="currUser!=''" >{{currUser}}</el-button>
            <el-button v-if="msg!=''" >{{msg}}</el-button>
        </div>

        <div class="row" v-if="enemyHandCardList.length > 0">
            <el-button >敌方手牌</el-button>
            <el-button v-for="(item,index) in enemyHandCardList" @click="onAction('selectCard', index)"
                       type="primary" >背面</el-button>
        </div>

        <div class="row" v-if="enemySkillList.length > 0">
            <el-button >使用招式</el-button>
            <el-button v-for="(item,index) in enemySkillList" @click="onAction('userCard', item.cardId)"
                       type="primary" >{{item.skillName}}</el-button>
        </div>

        <div class="row" >
            <el-button >敌方战场</el-button>
            <el-button v-if="enemyAppearCardMap[0]" @click="onAction('targetCard', 0)" type="primary" >
                {{enemyAppearCardMap[0].cardName}}-血{{enemyAppearCardMap[0].blood}}</el-button>
            <el-button v-else >空</el-button>
            <el-button v-if="enemyAppearCardMap[1]" @click="onAction('targetCard', 1)" type="primary" >
                {{enemyAppearCardMap[1].cardName}}-血{{enemyAppearCardMap[1].blood}}</el-button>
            <el-button v-else >空</el-button>
            <el-button v-if="enemyAppearCardMap[2]" @click="onAction('targetCard', 2)" type="primary" >
                {{enemyAppearCardMap[2].cardName}}-血{{enemyAppearCardMap[2].blood}}</el-button>
            <el-button v-else >空</el-button>
            <el-button v-if="enemyAppearCardMap[3]" @click="onAction('targetCard', 3)" type="primary" >
                {{enemyAppearCardMap[3].cardName}}-血{{enemyAppearCardMap[3].blood}}</el-button>
            <el-button v-else >空</el-button>
            <el-button v-if="enemyAppearCardMap[4]" @click="onAction('targetCard', 4)" type="primary" >
                {{enemyAppearCardMap[4].cardName}}-血{{enemyAppearCardMap[4].blood}}</el-button>
            <el-button v-else >空</el-button>
            <el-button v-if="enemyAppearCardMap[5]" @click="onAction('targetCard', 5)" type="primary" >
                {{enemyAppearCardMap[5].cardName}}-血{{enemyAppearCardMap[5].blood}}</el-button>
            <el-button v-else >空</el-button>
            <el-button v-if="enemyAppearCardMap[6]" @click="onAction('targetCard', 6)" type="primary" >
                {{enemyAppearCardMap[6].cardName}}-血{{enemyAppearCardMap[6].blood}}</el-button>
            <el-button v-else >空</el-button>

            <el-button >敌方选牌</el-button>
            <el-button v-for="(item,index) in enemyWaitCardList" @click="onAction('selectCard', index)"
                       type="primary" >选牌</el-button>

        </div>

        <div class="row" >
            <el-button >我方战场</el-button>

            <el-button v-if="userAppearCardMap[0]" @click="onAction('appearHero', 0)" type="primary" >
                {{userAppearCardMap[0].cardName}}-血{{userAppearCardMap[0].blood}}</el-button>
            <el-button v-else @click="onAction('appear', 0)">空</el-button>
            <el-button v-if="userAppearCardMap[1]" @click="onAction('appearHero', 1)" type="primary" >
                {{userAppearCardMap[1].cardName}}-血{{userAppearCardMap[1].blood}}</el-button>
            <el-button v-else @click="onAction('appear', 1)">空</el-button>
            <el-button v-if="userAppearCardMap[2]" @click="onAction('appearHero', 2)" type="primary" >
                {{userAppearCardMap[2].cardName}}-血{{userAppearCardMap[2].blood}}</el-button>
            <el-button v-else @click="onAction('appear', 2)">空</el-button>
            <el-button v-if="userAppearCardMap[3]" @click="onAction('appearHero', 3)" type="primary" >
                {{userAppearCardMap[3].cardName}}-血{{userAppearCardMap[3].blood}}</el-button>
            <el-button v-else @click="onAction('appear', 3)">空</el-button>
            <el-button v-if="userAppearCardMap[4]" @click="onAction('appearHero', 4)" type="primary" >
                {{userAppearCardMap[4].cardName}}-血{{userAppearCardMap[4].blood}}</el-button>
            <el-button v-else @click="onAction('appear', 4)">空</el-button>
            <el-button v-if="userAppearCardMap[5]" @click="onAction('appearHero', 5)" type="primary" >
                {{userAppearCardMap[5].cardName}}-血{{userAppearCardMap[5].blood}}</el-button>
            <el-button v-else @click="onAction('appear', 5)">空</el-button>
            <el-button v-if="userAppearCardMap[6]" @click="onAction('appearHero', 6)" type="primary" >
                {{userAppearCardMap[6].cardName}}-血{{userAppearCardMap[6].blood}}</el-button>
            <el-button v-else @click="onAction('appear', 6)">空</el-button>

            <el-button >我方选牌</el-button>

            <el-button v-for="(item,index) in userWaitCardList" @click="onAction('selectCard', index)"
                       type="primary" >{{item.cardName}}</el-button>

        </div>

        <div class="row" v-if="userSkillList.length > 0">
            <el-button >使用招式</el-button>
            <el-button v-for="(item,index) in userSkillList" @click="onAction('userSkill', index)"
                       type="primary" >{{item.skillName}}</el-button>
        </div>

        <div class="row" v-if="userHandCardList.length > 0">
            <el-button >我方手牌</el-button>
            <el-button v-for="(item,index) in userHandCardList" @click="onAction('userCard', item.cardId)"
                       type="primary" >{{item.cardName}}</el-button>
        </div>

        <div class="row" >
            <el-button @click="onAction('overTurn')" type="primary" >回合结束</el-button>
        </div>


    </div>
</template>

<script>
    export default {
        name: "shaDu",
        data() {
            return {
                webSocket: null,
                currUser: "",
                msg: '',
                selectCard:[],

                userExpend: 0,
                userWaitCardList: [],
                userHandCardList: [],
                userAppearCardMap: {},
                userSkillList: [],

                enemyExpend: 0,
                enemyWaitCardList: [],
                enemyHandCardList: [],
                enemyAppearCardMap: {},
                enemySkillList: [],

                currAppear:'',      //操作位置
                targetCardIndex: '',
            };
        },
        methods: {

            /**
             * 事件点击
             */
            onAction(e, i){
                switch (e) {
                    case 'userSkill':
                        this.sendMsg({
                            name: "userSkill",
                            value: {
                                enemyAppearCardIndex: this.targetCardIndex,
                                userAppearCardIndex: this.currAppear,
                                userSkillIndex: i
                            },
                        });
                        break;
                    case 'targetCard':
                        this.targetCardIndex = i;
                        break;
                    case 'appearHeroEnemy':
                        this.enemySkillList = this.enemyAppearCardMap[e].skillList;
                        break;
                    case 'appearHero':
                        this.currAppear = i;
                        this.userSkillList = this.userAppearCardMap[i].skillList;
                        break;
                    case 'userCard':
                        this.sendMsg({
                            name: "userCard",
                            value: {
                                appearIndex: this.currAppear,
                                cardId: i
                            },
                        });
                        break;
                    case 'appear':
                        this.currAppear = i;
                        break;
                    case 'loginA':
                        this.connectWebSocket("ws://127.0.0.1:6007/wsg?userA");
                        this.currUser = "玩家A";
                        break;
                    case 'loginB':
                        this.connectWebSocket("ws://127.0.0.1:6007/wsg?userB");
                        this.currUser = "玩家B";
                        break;
                    case 'startMatching':
                        this.sendMsg({
                            name: "startMatching",
                            value: "",
                        });
                        break;
                    case 'playerReady':
                        this.sendMsg({
                            name: "playerReady",
                            value: "",
                        });
                        break;
                    case 'selectCard':
                        this.sendMsg({
                            name: "selectCard",
                            value: i,
                        });
                        break;
                    case 'overTurn':
                        console.log("this.data", this);

                        this.sendMsg({
                            name: "overTurn",
                            value: '',
                        });
                        break;
                }
            },

            /**
             * 收到消息
             */
            onMessage(e){
                console.log("收到消息", e);
                this.msg = e.key;
                if(e.name == 'action'){
                    let actionData;
                    let card;
                    switch (e.key) {
                        case '游戏初始数据':
                            actionData = JSON.parse(e.data);
                            this.userExpend = actionData.userPlayer.playerExpend;
                            this.userWaitCardList = actionData.userPlayer.waitCardList;
                            this.userHandCardList = actionData.userPlayer.handCardList;
                            this.userAppearCardMap = actionData.userPlayer.appearCardMap;

                            this.enemyExpend = actionData.enemyPlayer.playerExpend;
                            this.enemyWaitCardList = actionData.enemyPlayer.waitCardList;
                            this.enemyHandCardList = actionData.enemyPlayer.handCardList;
                            this.enemyAppearCardMap = actionData.enemyPlayer.appearCardMap;

                            break;
                        case '我方三选一':
                            actionData = JSON.parse(e.data);
                            this.userWaitCardList = actionData;
                            break;
                        case '敌方三选一':
                            actionData = JSON.parse(e.data);
                            this.enemyWaitCardList = actionData;
                            break;
                        case '我方选择卡牌':
                            //获取卡牌
                            card = this.userWaitCardList[e.data];

                            this.userHandCardList.push(card);
                            this.userWaitCardList = [];

                            break;
                        case '敌方选择卡牌':
                            card = this.enemyWaitCardList[e.data];

                            this.enemyHandCardList.push(card);
                            this.enemyWaitCardList = [];
                            break;
                        case '我方使用的卡':
                            actionData = JSON.parse(e.data);
                            console.log("actionData", actionData);

                            //获取卡
                            card = this.userHandCardList[actionData.userCardIndex];
                            //加入场上
                            this.userAppearCardMap[actionData.appearIndex] = card;
                            //手牌移除
                            this.userHandCardList.splice(actionData.userCardIndex, 1);

                            break;
                        case '敌方使用的卡':
                            actionData = JSON.parse(e.data);
                            console.log("actionData", actionData);

                            //加入场上
                            this.enemyAppearCardMap[actionData.appearIndex] = actionData.userCard;
                            //手牌移除
                            this.enemyHandCardList.splice(actionData.userCardIndex, 1);

                            break;
                        case '我方使用招式':
                            actionData = JSON.parse(e.data);
                            console.log("actionData", actionData);
                            this.msg = "敌方受伤:" + actionData.enemyHarm;
                            if(actionData.userHarm > 0){
                                this.msg += ",我方受伤:" + actionData.userHarm
                            }
                            this.msg += ",我方招式:" + actionData.userSkill.skillName;
                            if(actionData.enemySkill && actionData.enemySkill.skillName){
                                this.msg += ", 敌方招式:" + actionData.enemySkill.skillName
                            }
                            this.enemyAppearCardMap[actionData.enemyAppearCardIndex] = actionData.enemyCard;
                            this.userAppearCardMap[actionData.userAppearCardIndex] = actionData.userCard;

                            break;
                        case '敌方使用招式':
                            actionData = JSON.parse(e.data);
                            console.log("actionData", actionData);
                            this.msg = "我方受伤:" + actionData.enemyHarm;
                            if(actionData.userHarm > 0){
                                this.msg += ",敌方受伤:" + actionData.userHarm
                            }
                            this.msg += ",敌方招式:" + actionData.userSkill.skillName;
                            if(actionData.enemySkill && actionData.enemySkill.skillName){
                                this.msg += ", 我方招式:" + actionData.enemySkill.skillName
                            }
                            this.userAppearCardMap[actionData.userAppearCardIndex] = actionData.enemyCard;
                            this.enemyAppearCardMap[actionData.enemyAppearCardIndex] = actionData.userCard;

                            break;
                    }
                }
            },


            connectWebSocket(wsUri) {
                this.webSocket = new WebSocket(wsUri);

                this.webSocket.onopen = this.onWebSocketOpen;
                this.webSocket.onmessage = this.onWebSocketMessage;
                this.webSocket.onerror = this.onWebSocketError;
                this.webSocket.onclose = this.onWebSocketClose;
            },

            onWebSocketMessage(event) {
                // console.log("收到消息:", event.data);
                let data = JSON.parse(event.data);
                this.onMessage(data);
            },

            onWebSocketOpen(event) {
                console.log("连接成功:", event);
            },
            onWebSocketError(event) {
                console.error("连接错误:", event);
            },
            onWebSocketClose(event) {
                console.log("连接已关闭:", event);
            },
            sendMsg(message) {
                if (this.webSocket) {
                    this.webSocket.send(JSON.stringify(message));
                }
            }
        },
        created() {

        },
        beforeDestroy() {
            if (this.webSocket) {
                this.webSocket.close();
            }
        }
    };
</script>
<style scoped>
    .row{
        position: relative;
        width: 95%;
        display: flex;
        justify-content: flex-start;
        margin-top: 20px;
        padding-left: 2%;
    }

</style>
