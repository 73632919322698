import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import * as elIcons from '@element-plus/icons-vue'
import {GET, POST, POST1, down} from './utils/api'
import tools from './utils/tools'
import verify from './utils/verify'
import dayjs from './utils/dayjs'

async function start() {
    const app = createApp(App);

    app.use(router);        //使用路由
    app.use(store);         //使用vuex
    app.use(ElementPlus);   //使用element

    //注册全局icon图标组件
    for (const [key, component] of Object.entries(elIcons)) {
        app.component(key, component)
    }

    //鉴权指令
    tools.accessDirective(app);

    app.mount('#app');

    // 配置全局属性
    app.config.globalProperties.$GET = GET;
    app.config.globalProperties.$POST = POST;
    app.config.globalProperties.$POST1 = POST1;
    app.config.globalProperties.$down = down;
    app.config.globalProperties.$tools = tools;
    app.config.globalProperties.$verify = verify;
    app.config.globalProperties.$dayjs = dayjs;


}
start();
