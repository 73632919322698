<template>
  <router-view></router-view>
</template>

<script setup></script>

<style>
  #app{
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    font-family: Helvetica Neue,Helvetica,PingFang SC,Hiragino Sans GB,Microsoft YaHei,SimSun,sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: transparent;
    font-size: 14px;
    overflow: hidden;
    position: relative;
  }
</style>
