import axios from 'axios';
import { ElLoading, ElMessage } from 'element-plus';
import router from '@/router/index'
import store from '@/store/index'
import { toRaw } from "vue";

// const baseUrl = "http://192.168.1.104:9000"; //本地服务器接口(白)
// const baseUrl = "http://192.168.1.10:9000"; //本地服务器接口(测试)
// const baseUrl = "http://192.168.1.69:9000"; //本地服务器接口(唐)
const baseUrl = "https://eoms.jxkj1987.cn/business"; //线上接口
const version = '2.0.9';

const pendingMap = new Map();

const LoadingInstance = {
    _target: null,
    _count: 0
};

function api(axiosConfig, customOptions, loadingOptions) {
    const service = axios.create({
        baseURL: baseUrl,   // 设置统一的请求前缀
        timeout: 60000,     // 设置统一的超时时长
    });

    // 请求拦截
    service.interceptors.request.use(
        config => {
            removePending(config);
            customOptions.isCancel && addPending(config);
            // 创建loading实例
            if (customOptions.loading) {
                LoadingInstance._count++;
                if(LoadingInstance._count === 1) {
                    LoadingInstance._target = ElLoading.service(loadingOptions);
                }
            }
            //设置活动页
            config.headers['activePageUUID'] = store.state.activePageUUID;
            config.headers['version'] = "b";
            //登录使用A版本
            if(config.url == "/author/EomsUser/pcWaitWxAppLogin"){
                config.headers['version'] = "a";
            }
            // 设置headers
            if(localStorage.getItem("tokenName")){
                config.headers[localStorage.getItem("tokenName")] = localStorage.getItem("tokenValue");
            }

            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );

    // 响应拦截
    service.interceptors.response.use(
        response => {
            removePending(response.config);
            customOptions.loading && closeLoading(customOptions); // 关闭loading
            if(response && response.data && response.data.code == 200){
                //console.log("请求返回", response.data)
                return response.data;
            }else{
                //业务层错误
                customOptions.isError && httpErrorStatus(response.data);  // 处理业务错误状态码
                return Promise.reject(response.data); // 错误继续返回给到具体页面
            }
        },
        error => {
            error.config && removePending(error.config);
            customOptions.loading && closeLoading(customOptions);   // 关闭loading
            customOptions.isError && httpErrorStatusHandle(error);  // 处理错误状态码
            return Promise.reject(error); // 错误继续返回给到具体页面
        }
    );

    return service(axiosConfig)
}

export default api;

export function ver(){
    return version;
}

export function base(){
    return baseUrl;
}

export function GET(url, isLoading=true, loadingText="loading...", contentType="application/json", isCancel=true, isError=true) {
    return api({
        url: url,
        method: 'get',
        headers: {
            'Content-Type': contentType
        },
    }, {
        loading: isLoading,     //是否开启loading层效果
        isCancel: isCancel,     //是否开启取消重复请求
        isError: isError,       //是否开启接口错误信息展示
    }, {
        text: loadingText,                  //消息提示文本
        background: 'rgba(255,255,255,0.4)',   //消息提示背景
    })
}

export function POST(url, param={}, isLoading=true, loadingText="loading...", isCancel=true, isError=true) {
    return api({
        url: url,
        method: 'post',
        data: param,
        headers: {
            'Content-Type': "application/json"
        },
    }, {
        loading: isLoading,     //是否开启loading层效果
        isCancel: isCancel,     //是否开启取消重复请求
        isError: isError,       //是否开启接口错误信息展示
    }, {
        text: loadingText,                  //消息提示文本
        background: 'rgba(255,255,255,0.4)',   //消息提示背景
    })
}

export function POST1(url, param={}, isLoading=true, loadingText="loading...", isCancel=true, isError=true) {
    return api({
        url: url,
        method: 'post',
        data: toRaw(param),
        headers: {
            'Content-Type': "application/x-www-form-urlencoded"
        },
    }, {
        loading: isLoading,     //是否开启loading层效果
        isCancel: isCancel,     //是否开启取消重复请求
        isError: isError,       //是否开启接口错误信息展示
    }, {
        text: loadingText,                  //消息提示文本
        background: 'rgba(255,255,255,0.4)',   //消息提示背景
    })
}

export function down(url) {
    let headers = {
        activePageUUID:store.state.activePageUUID
    };
    if(localStorage.getItem("tokenName")){
        headers[localStorage.getItem("tokenName")] = localStorage.getItem("tokenValue");
    }
    headers['version'] = "b";
    const loading =  ElLoading.service({
        text: "下载中...",                     //消息提示文本
        background: 'rgba(255,255,255,0.4)',   //消息提示背景
    });
    axios({
        method: 'get',
        url: baseUrl + url,
        responseType: 'blob',
        headers:headers
    }).then(res => {
        // console.log("下载返回", res)
        const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel,charset=utf-8",
        }); // 将文件流转换成blob
        const downloadElement = document.createElement("a");
        const href = window.URL.createObjectURL(blob);
        downloadElement.href = href;
        downloadElement.download = decodeURI(res.headers.filename);
        downloadElement.click();
        window.URL.revokeObjectURL(downloadElement.href);
        loading.close();
    }).catch(err => {
        loading.close();
    })
}



/**
 * 处理业务错误
 * @param {*} error
 */
function httpErrorStatus(data) {
    let message = '';
    if (data) {
        // console.log("业务错误", data)
        //特殊处理无效请求
        if(data.returnCode == 430){
            localStorage.setItem("tokenValue", "");
            router.push({name:'login'});
            ElMessage({
                type: 'warning',
                message: "请重新登录!",
            });
            return;
        }

        switch(data.code) {
            case 11011: message = '登录过期,请重新登录!';break;
            case 11012: message = '登录过期,请重新登录!';break;
            case 404: message = '登录过期,请重新登录!';break;
            case 11051: message = '无操作权限！'; break;
            default: message = data.msg; break
        }
    }
    ElMessage({
        type: 'warning',
        message
    });
    if(data.code == 11011 || data.code == 11012 || data.code == 404){
        localStorage.setItem("tokenValue", "");
        router.push({name:'login'});
    }
}

/**
 * 处理异常
 * @param {*} error
 */
function httpErrorStatusHandle(error) {
    // 处理被取消的请求
    if(axios.isCancel(error)) return console.error('请求的重复请求：' + error.message);
    let message = '';
    if (error && error.response) {
        console.log("处理异常", error.response.status)
        switch(error.response.status) {
            case 302: message = '接口重定向了！';break;
            case 400: message = '参数不正确！';break;
            case 401: message = '您未登录，或者登录已经超时，请先登录！';break;
            case 403: message = '您没有权限操作！'; break;
            case 404: message = `请求地址出错: ${error.response.config.url}`; break; // 在正确域名下
            case 408: message = '请求超时！'; break;
            case 409: message = '系统已存在相同数据！'; break;
            case 500: message = '服务器内部错误！'; break;
            case 501: message = '服务未实现！'; break;
            case 502: message = '网关错误！'; break;
            case 503: message = '服务不可用！'; break;
            case 504: message = '服务暂时无法访问，请稍后再试！'; break;
            case 505: message = 'HTTP版本不受支持！'; break;
            default: message = '异常问题，请联系管理员！'; break
        }
    }
    if (error.message.includes('timeout')) message = '网络请求超时！';
    if (error.message.includes('Network')) message = window.navigator.onLine ? '服务端异常！' : '您断网了！';

    ElMessage({
        type: 'error',
        message
    })
}

/**
 * 关闭Loading层实例
 * @param {*} _options
 */
function closeLoading(_options) {
    if(_options.loading && LoadingInstance._count > 0) LoadingInstance._count--;
    if(LoadingInstance._count === 0) {
        LoadingInstance._target.close();
        LoadingInstance._target = null;
    }
}

/**
 * 储存每个请求的唯一cancel回调, 以此为标识
 * @param {*} config
 */
function addPending(config) {
    const pendingKey = getPendingKey(config);
    config.cancelToken = config.cancelToken || new axios.CancelToken((cancel) => {
        if (!pendingMap.has(pendingKey)) {
            pendingMap.set(pendingKey, cancel);
        }
    });
}

/**
 * 删除重复的请求
 * @param {*} config
 */
function removePending(config) {
    const pendingKey = getPendingKey(config);
    if (pendingMap.has(pendingKey)) {
        const cancelToken = pendingMap.get(pendingKey);
        // 如你不明白此处为什么需要传递pendingKey可以看文章下方的补丁解释
        cancelToken(pendingKey);
        pendingMap.delete(pendingKey);
    }
}

/**
 * 生成唯一的每个请求的唯一key
 * @param {*} config
 * @returns
 */
function getPendingKey(config) {
    let {url, method, params, data} = config;
    try {
        if(typeof data === 'string') data = JSON.parse(data); // response里面返回的config.data是个字符串对象
        return [url, method, JSON.stringify(params), JSON.stringify(data)].join('&');
    }catch (err) {
        return url + method + data + "";
    }

}
