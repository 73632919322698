<template>
    <el-container class="mainContent" >
        <!-- 侧边菜单-->
        <el-aside class="menu" :style="{'width':isCollapse?'64px':'200px'}">
            <el-scrollbar>
                <el-menu mode="vertical" :default-active="currRouter.path" :collapse="isCollapse" unique-opened >
                    <div class="menu_head"  >
                        <span v-if="!isCollapse" >PC助手教程</span>
                    </div>
<!--                    <div class="menu_search"  >-->
<!--                        <el-input v-model="word" style="width: 180px;margin-left: 10px;margin-bottom: 10px;" @input="searchInput"-->
<!--                                  placeholder="菜单快速检索" prefix-icon="Search"></el-input>-->
<!--                    </div>-->
                    <div v-for="(item,index) in menuList" :key="item.name">
                        <!-- 二级菜单,有子菜单 -->
                        <template v-if="item.children&&item.children.length>0">
                            <!-- 一级菜单-->
                            <el-sub-menu :index="item.path" >
                                <template #title>
                                    <el-icon ><component is="Document" ></component></el-icon>
                                    <span>{{item.meta.title}}</span>
                                </template>
                                <!-- 二级菜单-->
                                <template v-for="(item1,index1) in item.children" :key="item1.name" >
                                    <el-menu-item class="menu_item" :class="currRouter.name==item1.name?'menu_item_actor':''"
                                                  @click="menuClick(item1)"
                                                  :index="item1.path" v-if="item1.path">
                                        <el-icon ><component is="Document" ></component></el-icon>
                                        <template #title>{{item1.meta.title}}</template>
                                    </el-menu-item>
                                </template>
                            </el-sub-menu>

                        </template>
                        <!-- 一级菜单,无子菜单 -->
                        <template v-else>
                            <!-- 一级菜单-->
                            <el-menu-item class="menu_item" :class="currRouter.name==item.name?'menu_item_actor':''" @click="menuClick(item)"
                                          :index="item.path" >
                                <el-icon ><component is="Document" ></component></el-icon>
                                <template #title>{{item.meta.title}}</template>
                            </el-menu-item>
                        </template>
                    </div>
                </el-menu>
            </el-scrollbar>
        </el-aside>
        <!-- 窗口 -->
        <el-container>
            <!-- 头部 -->
            <el-header class="head">
                <el-scrollbar ref="scrollContainer" style="width: calc(100% - 120px);">
                    <div class="head_tab" >
                        <div class="head_tab_item" :class="currRouter.name=='home'?'active':''" @click="tabClick('home')">
                            <div class="head_tab_item_text">新手教程</div>
                        </div>
                        <div class="head_tab_item" :class="currRouter.name==item.name?'active':''" ref="item.name"
                             v-for="(item,index) in tabList" :key="item.name" @click="tabClick(item.name)"
                             @contextmenu.prevent="openMenu(item,$event)">
                            <div class="head_tab_item_text">{{item.meta.title}}</div>
                            <el-icon class="head_tab_item_icon" :class="currRouter.name==item.name?'active1':''" title="关闭"
                                     @click.stop="tabClose(item.name)"><Close /></el-icon>
                        </div>
                    </div>
                </el-scrollbar>
                <ul v-show="visible" class="contextmenu" :style="{left:left+'px',top:top+'px'}" >
                    <li class="contextmenuLi" @click.stop="contextmenu(0)">关闭其他</li>
                    <li class="contextmenuLi" @click.stop="contextmenu(1)">全部关闭</li>
                </ul>
                <div class="head_info">
                    <div class="head_info_item" @click="resIndex">
                        返回首页
                    </div>
                    <div class="head_info_item" @click="down">
                        助手下载
                    </div>
                </div>
            </el-header>
            <!-- 主体 -->
            <el-scrollbar>
                <el-main class="body">
                    <router-view v-slot="{ Component }" >
                        <transition name="slide-right" mode="out-in">
                            <keep-alive :include="getKeepAliveList">
                                <component :is="Component" :key="$route.fullPath"  />
                            </keep-alive>
                        </transition>
                    </router-view>
                </el-main>
            </el-scrollbar>
        </el-container>
    </el-container>
</template>

<script>
    export default {
        data() {
            return {
                documentObj : null,     //head左右滑动用到的元素对象
                visible: false,         //head右键菜单显示开关
                top: 0,
                left: 0,
                currItem:null,          //当前打开右键菜单的tab
                isCollapse: false,
                word:'',
            }
        },
        computed:{
            menuList() {
                return this.$store.state.menuList;
            },
            // 获取缓存列表
            getKeepAliveList () {
                return this.$store.getters.getKeepAliveList
            },
            tabList() {
                return this.$store.state.tabList;
            },
            currRouter() {
                return this.$store.state.currRouter;
            },
            userInfo() {
                return this.$store.state.userInfo;
            },
        },
        watch: {
            visible(value) {
                if (value) {
                    document.body.addEventListener('click', this.closeMenu)
                } else {
                    document.body.removeEventListener('click', this.closeMenu)
                }
            }
        },
        methods:{

            /**
             * 搜索菜单
             */
            searchInput(e){
                console.log("e", e)
            },

            /**
             * 折叠点击
             */
            collapseTap(){
                this.isCollapse = !this.isCollapse
            },

            /**
             * 左侧菜单点击
             */
            menuClick: function (e) {
                console.log("路径", e)
                this.$router.push({
                    name:e.name
                });
            },

            /**
             * 返回登录页
             */
            resIndex: function(){
                localStorage.setItem("routerName", "");
                this.$router.push('login');
            },

            /**
             * 下载助手
             */
            down: function(){
                this.$message.success("程序下载中...");
                location.href = this.$tools.url;
            },

            /**
             * tab点击
             */
            tabClick: function (name) {
                this.$router.push({
                    name:name
                });
            },

            /**
             * tab关闭点击
             */
            tabClose: function (name) {
                let tabList = this.$store.state.tabList;
                let currRouter = this.$store.state.currRouter;
                //判断关闭后显示页面
                for (let i = 0; i < tabList.length; i++) {
                    if(tabList[i].name == name){
                        tabList.splice(i,1);
                        i--;
                        if(name == currRouter.name){
                            if(tabList.length > 0 && tabList[i] && tabList[i].name){
                                this.$router.push({
                                    name:tabList[i].name
                                });
                            }else{
                                this.$router.push({
                                    name:'home'
                                });
                            }
                        }
                    }

                }
            },

            /**
             * 打开tab菜单
             * @param tab
             * @param e
             */
            openMenu: function(tab, e) {
                this.top = e.clientY - 10;
                this.left = e.clientX - 230;
                this.visible = true;
                this.currItem = tab;
            },

            /**
             * 关闭tab菜单
             */
            closeMenu: function() {
                this.visible = false
            },

            /**
             * tab菜单点击
             * @param e
             */
            contextmenu:function (e) {
                let tabList = this.$store.state.tabList;
                //关闭其他
                if(e === 0){
                    for (let i = 0; i < tabList.length; i++) {
                        if(tabList[i].name != this.currItem.name){
                            tabList.splice(i,1);
                            i--;
                        }
                    }
                }
                //关闭全部
                if(e === 1){
                    for (let i = 0; i < tabList.length; i++) {
                        tabList.splice(i,1);
                        i--;
                    }
                    this.$router.push({name:'home'});
                }
                this.visible = false;
            },

        },
    };
</script>

<style scoped>
    .mainContent{
        position: relative;
        width: 100vw;
        height: 100vh;
        color: #333333;
        background: #f2f2f2;
        min-width: 1366px;
        min-height: 768px;
    }

    :deep(.el-menu) {
        border-right: none;
        /*transition: none;*/
    }
    :deep(.el-menu-item.is-active) {
        color: #ff6633;
    }

    .menu{
        position: relative;
        margin: 14px 0 14px 14px;
        height: calc(100vh - 28px);
        overflow: hidden;
        transition: width 0.3s ease;
        box-shadow: 1px 2px 9px 1px rgba(73, 73, 73, 0.1);
        border-radius: 8px;
        background: #ffffff;
    }

    .menu_head{
        position: sticky;
        top: 0;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ff6633;
        font-weight: 800;
        font-size: 24px;
        overflow: hidden;
        text-overflow: clip;
        white-space: nowrap;
        z-index: 9;
        background: #ffffff;
        cursor: pointer;
    }
    .menu_head_title{
        position: relative;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: clip;
        margin-right: 10px;
    }
    .menu_head_icon{
        position: relative;
        margin-top: 4px;
        cursor: pointer;
    }
    .menu_head_icon1{
        position: relative;
        cursor: pointer;
    }

    :deep(.el-sub-menu__title:hover) {
        background-color: #ffece6;
        color: #ff6633;
    }

    .menu_item{
        height: 50px;
        font-size: 15px;
    }
    .menu_item_actor{
        background-color: #ffece6;
        color: #ff6633;
        border-left: 5px solid #ff6633;
    }
    .menu_item:hover{
        background-color: #ffece6;
        color: #ff6633;
    }
    .menu_item:focus{
        background-color: #ffe5d5;
        color: #ff6633;
        border-left: 5px solid #ff6633;
    }

    .head{
        position: relative;
        height: 60px;
        margin-top: 14px;
        margin-left: 14px;
        margin-right: 14px;
        margin-bottom: 12px;
        box-shadow: 1px 2px 9px 1px rgba(73, 73, 73, 0.1);
        border-radius: 8px;
        background: #ffffff;
        display: flex;
        justify-content: start;
        align-items: center;
    }
    .head_tab{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 60px;
    }
    :deep(.el-header) {
        --el-header-padding: 0 10px;
    }
    .head_tab_item{
        position: relative;
        height: 32px;
        border: 1px solid #ff6633;
        color: #ff6633;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
        white-space: nowrap;
    }
    .active{
        background: #ff6633;
        color: #ffffff;
    }
    .head_tab_item:hover{
        cursor: pointer;
    }
    .head_tab_item_text{
        position: relative;
        font-size: 12px;
        padding-left: 12px;
        padding-right: 12px;
    }
    .head_tab_item_icon{
        position: relative;
        color: #ff233e !important;
        margin-right: 5px;
        margin-left: -10px;
        font-size: 15px;
        z-index: 9;
        border-radius: 2px;
    }
    .head_tab_item_icon:hover{
        background: rgba(255, 14, 12, 0.8);
        border-radius: 50%;
        color: #f2f2f2 !important;
    }
    .active1{
        color: #f2f2f2 !important;
    }
    .contextmenu {
        margin: 0;
        background: #fff;
        z-index: 3000;
        position: absolute;
        list-style-type: none;
        padding: 5px 0;
        border-radius: 4px;
        font-size: 12px;
        font-weight: 400;
        color: #333;
        box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);
    }
    .contextmenuLi {
        margin: 0;
        padding: 7px 16px;
        cursor: pointer;
    }
    .contextmenuLi:hover {
        background: #eee;
    }

    .head_info{
        position: relative;
        width: 140px;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: hidden;
        cursor: pointer;
    }
    .head_info_item{
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ff6633;
        margin-right: 10px;
    }
    .head_info_item_img{
        position: relative;
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
    .head_info_item_text{
        position: relative;
        font-size: 18px;
        margin-left: 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 80px;
    }

    .body{
        position: relative;
        margin-bottom: 14px;
        margin-right: 14px;
        margin-left: 14px;
        box-shadow: 1px 2px 9px 1px rgba(73, 73, 73, 0.1);
        border-radius: 8px;
        background: #ffffff;
        padding: 14px;
        padding-bottom: 8px;
    }
</style>
