/**
 * 自定义校验规则类
 */
let verify;
export default verify = {

    /**
     * 校验数据
     */
    validate: function (type, value) {
        return this[type](null,value, res=>{}, "1")
    },

    /**
     * 校验是否为空
     */
    nothing: function (rule, value, callback, type="form") {
        if (value === null || typeof value === 'undefined' || value === '' || (value+"").trim() === '') {
            if(type == "form"){
                callback(new Error());
            }else{
                return false
            }
        }
        if(type == "form"){
            callback(new Error());
        }else{
            return true
        }
    },

    /**
     * 校验数字
     */
    number: function (rule, value, callback) {
        if (value == null || value === '') {
            callback();
        }
        let reg = /^[0-9]*$/;
        if (!reg.test(value)) {
            callback(new Error());
        }
        callback();
    },

    /**
     * 校验数字和字母(包含大小写)和-/_=+.,
     */
    numLetter: function (rule, value, callback) {
        if (value == null || value === '') {
            callback();
        }
        let reg = /^[A-Za-z0-9-/_=+.,]+$/;
        if (!reg.test(value)) {
            callback(new Error());
        }
        callback();
    },

    /**
     * 校验手机号
     */
    phone: function (rule, value, callback) {
        if (value == null || value === '') {
            callback();
        }
        let reg = /^(13[0-9]|14[01456879]|15[0-3,5-9]|16[2567]|17[0-8]|18[0-9]|19[0-3,5-9])\d{8}$/;
        if (!reg.test(value)) {
            callback(new Error());
        }
        callback();
    },

    /**
     * 校验邮箱
     */
    email: function (rule, value, callback) {
        if (value == null || value === '') {
            callback();
        }
        let reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if (!reg.test(value)) {
            callback(new Error());
        }
        callback();
    },

    /**
     * 校验价格
     */
    money: function (rule, value, callback) {
        if (value == null || value === '') {
            callback();
        }
        let reg = /^([0-9][0-9]*)+(.[0-9]{1,2})?$/;
        if (!reg.test(value)) {
            callback(new Error());
        }
        callback();
    },

    /**
     * 校验座机电话
     */
    mobilePhone1: function (rule, value, callback) {
        if (value == null || value === '') {
            callback();
        }
        let reg = /[0-9%&=_-]/;
        if (!reg.test(value)) {
            callback(new Error());
        }
        callback();
    },

    /**
     * 校验域名
     */
    url: function (rule, value, callback) {
        if (value == null || value === '') {
            callback();
        }
        let reg = /^http(s)?:\/\/(.*?)\//;
        if (!reg.test(value)) {
            callback(new Error());
        }
        callback();
    },

    /**
     * 校验合法IP地址格式
     */
    ip: function (rule, value, callback) {
        if (value == null || value === '') {
            callback();
        }
        let reg = /((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))/;
        if (!reg.test(value)) {
            callback(new Error());
        }
        callback();
    },


}

